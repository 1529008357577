$ ->
  update_mass_actions_collector = ->
    arr = []
    $("input.mass-actions-selector:checked").each (e)->
      arr.push($(this).attr("value"))

    $("#mass-action-selector-collector").val(arr.join(","))

    verify_student_selection($("input.mass-actions-selector:checked").length)

  verify_student_selection = (count) ->
    if count
      $('.bulk-actions-selector').removeClass('hide')
    else
      $('.bulk-actions-selector').addClass('hide')

    if count != $("input.mass-actions-selector").length
      $("#select-all-bar").addClass("hide")
    else
      $("#select-all-bar").removeClass("hide")

  $(document).on 'click','th.selector input', (e)->
    select_all = $(this).prop("checked")
    if select_all
      $("#select-all-bar").removeClass("hide")
    else
      $("#select-all-bar").addClass("hide")
    $("input.mass-actions-selector").prop('checked',select_all)
    $("input.mass-actions-selector").closest("table").addClass("selected-all-#{select_all}").removeClass("selected-all-#{!select_all}")
    update_mass_actions_collector()
    # resize container when bar appears / disappears
    $('.load-on-scroll').height ($(window).height() - ($('.load-on-scroll').offset().top)) - 30

  $(document).on 'change','input.mass-actions-selector', (e)->
    update_mass_actions_collector()

  $(document).on 'blur', '.routing-number', (e) ->
    $("#routing-number-bank-name").removeClass("error")
    $("#routing-number-bank-name").html("Verifying Routing Number...")

    $.ajax
      type: 'GET'
      url: "/api/v1/banks/"+$(this).val()
      contentType: 'application/json; charset=utf-8'
      data: ""
      success: (data) ->
        $("#routing-number-bank-name").html(data.name)
        $("#routing-number-bank-name").addClass("color-green")

      error: (req, status, error) ->
        $("#routing-number-bank-name").addClass("error")
        $("#routing-number-bank-name").html("Invalid Routing Number")

  # setup trigger for updating of selected ids for bullk action after getting rows (remote_rows.coffee)
  if $('.load-on-scroll').length
    $(document).ajaxSuccess ->
      update_mass_actions_collector()

  $("#bulk-action-options").change (e)->
    $(this).closest('form').submit()

  # intercept clicks on bulk action chosen list options that are locked and fetch
  # data attributes from the corresponding select element
  $(document).on 'click','#bulk_action_options_chosen .option-locked', (e)->
    e.preventDefault()
    corresponding_option = $("#bulk-action-options option").eq($(this).attr("data-option-array-index"))
    $("#bulk_action_locked_modal .alert-title").html(corresponding_option.attr("data-title"))
    $("#bulk_action_locked_modal .alert-message").html(corresponding_option.attr("data-message"))
    # show  modal
    $("#bulk_action_locked_modal").addClass("show fade-row").modal("show");
    $(".modal-backdrop").addClass("show");

  # disable checkboxes and set select all from saved view option to be true
  $("#select-view").click (e)->
    $("th.selector input").closest('table').addClass('saved-view-selected')
    $("#bulk_action_select_all").val(1).attr('value', 1)

  # deselect all checkboxes, revert flipper to front, re-enable checkboxes
  # and set saved view option to be true.
  $("#deselect-all").click (e)->
    $('#select-all-bar.flip-container').removeClass('flipped')
    $("#bulk_action_select_all").val(0).attr('value', 0)
    $("th.selector input").closest('table').removeClass('saved-view-selected')
    $("th.selector input").trigger('click');

  # flipper control - switch to rear
  $(document).on 'click', '.flip-to-rear', (e) ->
    $(this).closest('.flip-container').addClass('flipped')
  # flipper control - switch to front
  $(document).on 'click', '.flip-to-front', (e) ->
    $(this).closest('.flip-container').removeClass('flipped')

  # switch container content
  $(document).on 'click', '.content-switcher .switch-content', (e) ->
    container = $(e.target).closest('.content-switcher')
    alternate = container.find('.alternate-content')
    initial = container.find('.initial-content')
    if container.hasClass('switched')
      container.animate {opacity: 0}, 250, ->
        initial.css({ position: 'absolute' }).removeClass('hide')
        container.animate height: initial.outerHeight(), 250, ->
          alternate.addClass('hide')
          initial.css({ position: 'relative' })
          container.animate {opacity: 1}, 250
          container.removeClass('switched').css({ height: 'auto' })
    else
      container.animate {opacity: 0}, 250, ->
        alternate.css({ position: 'absolute' }).removeClass('hide')
        container.animate height: alternate.outerHeight(), 250, ->
          initial.addClass('hide')
          alternate.css({ position: 'relative' })
          container.animate {opacity: 1}, 250
          container.addClass('switched').css({ height: 'auto' })

$ ->
  #submits wrapping form
  $(".confirm-and-submit").click (e)->
    elementId = $(this).attr("data-trigger-click")
    if (elementId != "")
      $(elementId).trigger('click')

    $(this).closest("form").submit()
    # $('input').click(function(){ $('#form').removeAttr('data-remote') });
    # $('input').click(function(){ $('#form').attr('data-remote',true)});

  $(".confirm-and-submit-form").click (e)->
    # find the form wrapping the original trigger element.
    elementId = $(this).attr("data-trigger-click")
    $(elementId).closest("form").submit()

  $(".confirm-and-submit-ajax-delete").click (e)->
    elementId = $(this).attr("data-trigger-click")
    # console.log($(elementId).closest("form").attr('action'))
    $.ajax
      type: 'DELETE',
      url: $(elementId).closest("form").attr('action'),
      dataType: 'script',
      format: 'js',
      beforeSend: (xhr) ->
        xhr.setRequestHeader 'X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')
        return

  $(".confirm-and-go").click (e)->
    elementId = $(this).attr("data-trigger-click")
    window.location = $(elementId).attr("href")

  $(document).on 'click', '.confirm-this', (e) ->
    e.preventDefault()
    # pass the id of trigger element to the modal confirm button so it can be used on confirm.
    $("#{$(this).attr("data-target")} .confirms").attr("data-trigger-click", "##{$(this).attr("id")}")

  $(".trigger-click").click (e)->
    e.preventDefault()
    #console.log $("##{$(this).attr("data-trigger-click")}")
    $("##{$(this).attr("data-trigger-click")}").trigger("click")

  $(".trigger-click-and-close-dropdown").click (e)->
    e.preventDefault()
    $("##{$(this).attr("data-trigger-click")}").trigger("click")
    dropdown = $(e.target).closest('.dropdown-button')
    if dropdown.hasClass('show')
      $(e.target).closest('.dropdown-button').trigger("click")

$ ->
  $(".auto-submit-form-in-place").click (e)->
    form = $(this).closest('form')
    form.preventDefault
    valuesToSubmit = form.serialize()
    $.ajax form.attr('action'),
      type: 'PATCH'
      data: valuesToSubmit
      beforeSend: (xhr) -> xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
      success: (data, textStatus, jqXHR) ->
        console.log 'success', textStatus
      error: (data, textStatus, jqXHR) ->
        console.log 'error', data
    return

$ ->
  $(".auto-submit-form").change (e)->
    $(this).closest('form').submit()

  $(document).on 'click', '.click-element-by-data-attribute', (e) ->
    target_element = $("#" + $(this).attr("data-target-element"))
    target_element.click()
    $(this).closest('table').addClass("is-loading")


$ ->
  enforce_checkbox_exclusivity = (checkbox) ->
    excludes_array = checkbox.attr("data-excludes").split(',')
    checkbox_group_container = checkbox.closest('.check_boxes')
    $.each excludes_array, (index, value) ->
      excluded_checkbox = checkbox_group_container.find(".option-#{value.trim()}")
      if checkbox.prop('checked')
        excluded_checkbox.attr('disabled': true)
        excluded_checkbox.closest('.checkbox').addClass('disabled')
      else
        #only disable if there isn't another option checked that excludes this one
        if checkbox_group_container.find("input:checked.excludes-#{value.trim()}").length < 1
          excluded_checkbox.attr('disabled': false)
          excluded_checkbox.closest('.checkbox').removeClass('disabled')

  $(document).on 'change', '.check_boxes .has-exclusivity', (e) ->
    enforce_checkbox_exclusivity($(e.target))

  # trigger checkbox exclusivity check on load for each option
  $('.check_boxes .has-exclusivity').each (e) ->
    $(this).trigger('change')

$ ->
  # grid column radios / checkboxes
  $(document).on 'click', '.input-column', (e) ->
    $(this).toggleClass('all-checked')
    all_checked = $(this).hasClass('all-checked')
    # check or reset the column
    $(".column-option-#{$(this).attr("data-option-type")}").each (e)->
      $(this).prop('checked', all_checked).change()
    # if resetting then revert inputs to original state
    $(".originally-checked .column-option").prop('checked', true).change() if !all_checked

  # trigger change event on click event
  $(document).on 'click', '.column-option', (e) ->
    $(this).change()
  # update the form inputs with grid input changes
  $(document).on 'change', '.column-option', (e) ->
    $("#course_registration_class_time_#{$(this).attr("data-element-id")}").attr('value', $(this).val())
    if columns_changed()
      $(".check-for-changes").addClass("changes-made")
    else
      $(".check-for-changes").removeClass("changes-made")

  columns_changed = ->
    has_changes = false
    $(".column-option").each (e) ->
      # true if the input is checked but not originally checked.
      has_changes = true if !$(this).closest('.input-cell').hasClass('originally-checked') && $(this).prop("checked")
    return has_changes

$ ->
  $('.academic-year-card').click (e) ->
    $(this).find('input[type="radio"]').trigger 'click'
    return
  $('.academic-year-card input[type="radio"]').click (e) ->
    e.stopPropagation()
    return

$ ->
  # IMAGE UPLOAD PREVIEW
  readURL = (input) ->
    preview = $(input).closest('.upload-with-preview').find('.image-preview')
    if input.files and input.files[0]
      reader = new FileReader

      reader.onload = (e) ->
        preview.attr 'src', e.target.result
        preview.removeClass('hide')
        return

      reader.readAsDataURL input.files[0]
    return

  $(document).on 'change', '.upload-with-preview .show-preview', (e) ->
    return if e.target.value == ''
    error = $(e.target).closest('.upload-with-preview').find('.error')
    fileExtensions = [
      'jpeg'
      'jpg'
      'png'
    ]
    if $.inArray($(e.target).val().split('.').pop().toLowerCase(), fileExtensions) == -1
      $(e.target).closest('.upload-with-preview').find('.image-preview').addClass('hide')
      e.target.value = ''
      error.removeClass('hide').html(error.attr('data-error-text') + ' ' + fileExtensions.join(', '))
    else
      $(e.target).closest('.upload-with-preview').find('.image-preview').removeAttr('src')
      error.addClass('hide').html('')
      readURL(e.target)
    return
