$ ->
  $(".view-dropdown-row").click ->
    $(this).closest('tr').toggleClass('dropdown-open')
    $(this).closest('tr').nextAll(".dropdown-row:first").toggleClass('open')

  $(document).on 'click', '.dropdown-row-trigger', ->
    $(this).closest("tr").find(".view-dropdown-row").trigger('click')

$ ->
  # improves the alignment of columns in multiple grids of the same type on a page
  align_rows = ->
    first_table_headers = $(".align-grid-headers thead").eq(0).find("th")
    first_table_headers.each (index) ->
      width = $(this).width()
      $(".align-grid-headers thead").each (i) ->
        $(this).find("th").eq(index).width( width )


  align_rows()

  if $(".align-grid-headers").length
    $(window).on 'resize', (e) ->
      align_rows()
