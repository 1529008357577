console.log "CoffeeScript file loaded"

mobile_detected = false

combineDateTime = (element) ->
  console.log "combineDateTime called for element:", element
  hiddenInput = $(element).closest('.input').find('.datetime-value')
  dateInput = $(element).closest('.input').find('.dateonlypicker')
  timeInput = $(element).closest('.input').find('.timepicker')
  selected_date = $(element).closest('.input').find('.dateonlypicker').datepicker("getDate")

  console.log "Selected date from datepicker:", selected_date

  if dateInput.length && selected_date != null
    # moment.js is used to format the date to ISO 8601 format
    iso_date = moment.parseZone(selected_date).format("YYYY-MM-DD")
    console.log "ISO Date formatted:", iso_date

  combinedInput = ""
  if dateInput.length && timeInput.length
    combinedInput = "#{iso_date} #{timeInput.val()}"
    console.log "Date and Time combined:", combinedInput
  else if timeInput.length
    combinedInput = timeInput.val()
    console.log "Only Time provided:", combinedInput
  else if dateInput.length
    combinedInput = iso_date
    console.log "Only Date provided:", combinedInput

  console.log "Setting combined input value:", combinedInput
  $(hiddenInput).val(combinedInput).attr("value", combinedInput)

searchFocus = (element) ->
  console.log "searchFocus called with element:", element
  $(".header_search").focus()

class window.Fields
  renderAll: ->
    console.log "Rendering all fields"
    this.renderAnchors()
    this.renderFields()
    if mobile_detected
      this.mobileSelects()
    else
      this.renderChosens()
    this.renderTimePickers()
    this.renderDatePickers()
    this.setupDateTimePrevalidation()
    this.checkDateTimePrevalidation()

  renderForFormBuilder: ->
    console.log "Rendering for form builder"
    this.preRenderFormBuilderFields()
    this.renderFields()
    if mobile_detected
      this.mobileSelects()
    else
      this.renderChosens()

  renderAnchors: ->
    console.log "Rendering anchors"
    $("#page-content a[href^='http']").not('.same-tab').each ->
      $(this).attr("target", "_blank")

  renderChosens: ->
    console.log "Rendering chosen select elements"
    $("select").not('.element-initiated').addClass('element-initiated').each ->
      $(this).chosen({
        placeholder_text_single: window.placeholder_text_single,
        placeholder_text_multiple: window.placeholder_text_multiple,
        no_results_text: window.default_no_result_text
      })
    $(".chosen-single").each ->
      $(this).addClass "error" if $(this).parent().prev().hasClass "error"

  mobileSelects: ->
    console.log "Rendering mobile selects"
    $("select").not('.element-initiated').addClass('element-initiated').wrap( "<div class='mobile-select'></div>" );

  renderTimePickers: ->
    console.log "Rendering time pickers"
    $(".timepicker").not('.element-initiated').addClass('element-initiated').each ->
      $(this).timepicker(
        step: 15
        appendTo: $(this).closest('div')
      )
      console.log "Timepicker initialized for:", $(this)

      # Log the initial value of the timepicker on page load
      initialTime = $(this).val()
      console.log "Initial time value for timepicker:", initialTime

      $(this).on 'change', (e) ->
        console.log "Time picker changed:", $(e.target).val()
        combineDateTime($(e.target))
      # UGLY FIX FOR ISSUE HANDLING 0 SECONDS - PROBLEM IN Timepicker jquery code handling 0 value for seconds
      # ADD 1 second if value is 0. Then 12:00 AM will select correctly.
      $(this).on 'showTimepicker', (e) ->
        console.log "Timepicker shown"
        $(".ui-timepicker-list .ui-timepicker-am[data-time='0']").attr("data-time", 1)

  renderDatePickers: ->
    console.log "Rendering date pickers"
    $('.date_filter').each ->
      $(this).addClass('dateonlypicker')
      $(this).attr("data-date-format", $("body").attr("data-date-format"))

    $(".dateonlypicker").not('.element-initiated').addClass('element-initiated').each ->
      this_picker = $(this)
      $(this).datepicker(
        zIndexOffset: 15,
        language: document.documentElement.lang,
        changeMonth: true,
        changeYear: true,
        autoclose: true,
        uiLibrary: 'bootstrap4',
        format: $(this).attr("data-date-format"),
        todayHighlight: true,
        toggleActive: true
      )

      console.log "Datepicker initialized for:", $(this)

      # Log the initial value of the datepicker on page load
      initialDate = $(this).datepicker("getFormattedDate", $(this).attr("data-date-format"))
      console.log "Initial date value for datepicker:", initialDate

      $(this).on 'change', (e) ->
        console.log "Date picker changed:", $(e.target).val()
        combineDateTime($(e.target))

      $(this).on 'show', (e) ->
        if e.date
          $(this).data 'stickyDate', e.date
        else
          $(this).data 'stickyDate', null

      keyed_date = null

      $(this).on 'keyup', (e) ->
        keycode = if e.keyCode then e.keyCode else e.which
        if keycode != '13' # enter
          # store the last date entered via keys
          keyed_date = this_picker.datepicker('getFormattedDate', this_picker.attr("data-date-format"))

      $(this).on 'hide', (e) ->
        # use last keyed date or default sticky date
        stickyDate = keyed_date || $(this).data('stickyDate')
        if !e.date and stickyDate
          $(this).datepicker 'setDate', stickyDate
          $(this).data 'stickyDate', null

        console.log "Datepicker shown for:", $(this)


  setupDateTimePrevalidation: ->
    #set up validation
    console.log "Setting up date-time prevalidation"
    $(".input.datetime").not('.prevalidated').addClass('prevalidated').each ->
      $(this).find('input.timepicker, input.dateonlypicker').each ->
        # set up error message for each date or time input
        $("<div class='date-error-text hide'>#{$(this).attr('data-error-text')}</div>").insertBefore(this)
        # set up validation check on change of each date or time input
        $(this).change ->
          checkDateTimePrevalidationForInput(this)

  checkDateTimePrevalidation: ->
    console.log "Checking date-time prevalidation"
    $(".input.datetime .timepicker, .input.datetime .dateonlypicker").each ->
      checkDateTimePrevalidationForInput(this)

  checkDateTimePrevalidationForInput = (element = null) ->
    console.log "Validating input:", element
    container = $(element).closest('.input.datetime')

    # Ensure that timepicker and dateonlypicker exist
    timepicker = container.find('input.timepicker')
    dateonlypicker = container.find('input.dateonlypicker')

    if timepicker.length == 0
      console.log "Error: timepicker not found, skipping validation"
      return
    if dateonlypicker.length == 0
      console.log "Error: dateonlypicker not found, skipping validation"
      return

    submit_button = container.closest('form').find('button[type="submit"]')

    # disable submit button by default
    # Log the values to debug
    console.log "Timepicker value:", timepicker.val() if timepicker.length
    console.log "Datepicker value:", dateonlypicker.val() if dateonlypicker.length

    # Ensure timepicker and dateonlypicker have values before proceeding
    if timepicker.val() == undefined || dateonlypicker.val() == undefined
      console.log "One of the inputs is undefined, skipping validation"
      return

    # Validation logic...
    submit_button.addClass('datetime-error')

    # check all datetime dual inputs
    validates = true
    $('input.timepicker, input.dateonlypicker').each ->
      this_container = $(this).closest('.input.datetime')
      if this_container.length > 0
        # false if only one input has been filled in
        validates = false if $(this).val() == '' && (($(this).hasClass('timepicker') && this_container.find('input.dateonlypicker').val() != '') || ($(this).hasClass('dateonlypicker') && this_container.find('input.timepicker').val() != '' ))

    # enable submit all dtate times on page validate
    submit_button.removeClass('datetime-error') if validates

    # show / hide error messages for this set of inputs
    # Show error messages for time or date input if necessary
    if timepicker.val() == '' && dateonlypicker.val() != ''
      timepicker.prev('.date-error-text').removeClass('hide')
      console.log "Timepicker error shown"
    else
      timepicker.prev('.date-error-text').addClass('hide')

    if dateonlypicker.val() == '' && timepicker.val() != ''
      dateonlypicker.prev('.date-error-text').removeClass('hide')
      console.log "Datepicker error shown"
    else
      dateonlypicker.prev('.date-error-text').addClass('hide')


  preRenderFormBuilderFields: ->
    $(".form-elements input[type=radio].option-attr").not('.element-initiated').addClass('element-initiated').wrap("<label></label>").after("<span class='letter-radio'></span>")
    $(".form-elements input[type=radio]").not('.element-initiated').wrap("<span class='input'><span class='radio'><label></label></span></span>")
    $(".form-elements input[type=checkbox]").not('.element-initiated').wrap("<label class='checkbox'></label>")

  renderFields: ->
    # diable autocomplete
    $("form").attr("autocomplete", "off")
    $(":input").attr("autocomplete", "off")

    $("input[type=radio]").not('.element-initiated').not('.custom-radio').addClass('element-initiated').each ->
      $(this).parent().append("<span class='radio-dot'></span>")

    # regular checkboxes
    $("input[type=checkbox]").not('.element-initiated').not('.toggler-checkbox').not('.custom-checkbox').addClass('element-initiated').each ->
      $(this).parent().append("<span class='checkmark'></span>")

    # toggler 'checkboxes'
    $(".toggler-label").addClass('element-initiated').each ->
      thisToggler = $(this)
      thisToggler.append("<span class='toggler-switch'><span class='toggler-button-wrap'><span class='toggler-button-off fal #{$(this).attr('data-off-icon')}'></span><span class='toggler-button-on fal #{$(this).attr('data-on-icon')}'></span></span><span class='toggler-off fal #{$(this).attr('data-off-icon')}'></span><span class='toggler-on fal #{$(this).attr('data-on-icon')}'></span></span>")
      thisToggler.find(".toggler-checkbox").change ->
        if this.checked
          if thisToggler.attr("data-on-url") > ""
            thisToggler.find(".toggler-off").addClass('toggler-spinner')
            window.location = thisToggler.attr("data-on-url")
        else
          if thisToggler.attr("data-off-url") > ""
            thisToggler.find(".toggler-on").addClass('toggler-spinner')
            window.location = thisToggler.attr("data-off-url")

$ ->
  mobile_detected = $('body').hasClass('mobile_detected')
  admin_bar_detected = $('.student-info-bar-wrapper').length

  # duplicated in toggle-fields-coffee
  fields = new Fields
  fields.renderAll()

  # synch a trigger and a target elemnet
  $(document).on 'click','.toggle-by-id', (e)->
    target = $(this).attr("data-toggle-target-id")
    triggers = $("[data-toggle-target-id='#{target}']")
    if $(this).hasClass('opened')
      triggers.removeClass('opened')
      $("##{target}").addClass('hide')
    else
      triggers.addClass('opened')
      $("##{target}").removeClass('hide')

  # for self contained toggle blocks
  $(document).on 'click','.can-toggle .toggle-trigger', (e)->
    $(this).closest('.can-toggle').toggleClass('is-open').find('.toggle-target').toggleClass('target-open')

  #datagrid filter update and re-render form controls (chosens, checkboxes etc)
  $(document).on 'change', '.update-filters select, .update-filters input:not(.chosen-search-input)', (e) ->
    $('#datagrid-form-container').addClass('is-loading')
    $.get $(this).closest('form').attr('action') + '?get_filters=1', $(this).closest('form').serialize(), (data) ->
      $('#datagrid-form-container').html data.filters
      fields.renderAll()
      $('#datagrid-form-container').removeClass('is-loading')
      $('#filter-slide-menu .datagrid-actions').css "display", "flex"

  logic_index = 1

  $('body').on 'cocoon:after-insert', (e, insertedItem, originalEvent) ->
    fields.renderAll()
    # handle logic labels for hide/show functionality
    if $(originalEvent.currentTarget).hasClass('synch-logic-labels')
      $insertedItem = $(insertedItem)
      # loop through any logic labels and increment the id's for them and their associated triggers
      $insertedItem.find('[data-logic-label]').each ->
        logic_index += 1
        cloned_logic_label = $(this).attr('data-logic-label')
        unique_logic_label = "#{cloned_logic_label}-#{logic_index}"
        $(this).attr('data-logic-label', unique_logic_label)
        $insertedItem.find('[data-show]').attr('data-show', $insertedItem.find('[data-show]').attr('data-show').replace(cloned_logic_label, unique_logic_label))
        $insertedItem.find('[data-hide]').attr('data-hide', $insertedItem.find('[data-hide]').attr('data-hide').replace(cloned_logic_label, unique_logic_label))

    # trigger any show hide logic on the page to run again in case it applies to the html being insterted
    $('.hide-elements.run-on-insert, .show-elements.run-on-insert').trigger('change');


  # if a chosen dropdown has links in data-url attribute then go to url
  $(document).on 'change','select', (e) ->
   url = $(this).find('option:selected').data('url')
   window.location.href = url unless url == undefined

  $(document).on 'mouseup touchend', '.auto-blur', (e) ->
    $(this).blur()

  $(document).on 'click', '.alternate-text', (e) ->
    $(this).toggleClass 'alternate'

  $(".search-bar-header .toggle-trigger").click (e) ->
    e.preventDefault()
    setTimeout(searchFocus, 100)

  class window.AlignToolitps
    initializeTooltips: ->
      # tooltips
      $(document).on 'click', '[data-tooltip]', (e) ->
        tooltip = $(this).find('.click-tooltip')
        if !tooltip.length
          createTooltip(this, e.type)
          addPin(this)
        else
          $('td.last-clicked').not(tooltip.closest('td')).removeClass('last-clicked tooltip-pinned')
          $('.click-tooltip').not(tooltip).remove()
          removeTooltip(this, e.type)
          handlePin(this, tooltip)
        return

      $(document).on 'mouseover', '[data-tooltip]', (e) ->
        tooltip = $(this).find('.click-tooltip')
        if !tooltip.length
          createTooltip(this, e.type)

      $(document).on 'mouseout', '[data-tooltip]', (e) ->
        tooltip = $(this).find('.click-tooltip.tip-click')
        if !tooltip.length
          removeTooltip(this, e.type)

      $(document).on 'click', '.click-tooltip', (e) ->
        e.stopPropagation()

      # unpin tooltips when scrolling table to prevent overlap of table cells
      $('.table-wrap').scroll ->
        $('.datagrid td').removeClass("tooltip-pinned last-clicked")
        $('td .click-tooltip').remove()

    createTooltip = (element = null, trigger_method = "mouseover") ->
      styles = ""
      # build new
      if $(element).closest('.datagrid').length
        $(element).closest('td').addClass("last-clicked")
        if mobile_detected
          left_pos = 180 - $(element).offset().left
          styles = " style='left:" + left_pos + "px;' "
      $(element).append "<div #{styles} class='click-tooltip tip-#{trigger_method} #{positionClass(element)}'><div class='tooltip-content'>" + $(element).attr('data-tooltip') + '</div></div>'

    removeTooltip = (element = null, trigger_method = "mouseout") ->
      if (trigger_method == "click" && $(element).find('.click-tooltip.tip-click').length) || (trigger_method == "mouseout" && !$(element).find('.click-tooltip.tip-click').length)
        $('td.last-clicked').removeClass('last-clicked tooltip-pinned')
        $(element).find('.click-tooltip').remove()

    positionClass = (element = null) ->
      # assign position when inside table and a position hasn't already been given
      return if $(element).hasClass('tip-bottom') || $(element).hasClass('tip-top') || !$('.table-wrap').length
      elementTop = $(element).offset().top
      tableWrapTop = $('.table-wrap').offset().top
      if (elementTop - tableWrapTop) > 210
        "tip-top"
      else
        "tip-bottom"

    handlePin = (element, tooltip) ->
      # pin / unpin the tooltip on click
      if tooltip.hasClass("tip-click")
        tooltip.removeClass("tip-click")
        removePin(element)
      else
        tooltip.addClass("tip-click")
        addPin(element)

    addPin = (element) ->
      $(element).closest('td').addClass("tooltip-pinned") if $(element).closest('.datagrid').length

    removePin = (element) ->
      $(element).closest('td').removeClass("tooltip-pinned") if $(element).closest('.datagrid').length

  #tooltips = new AlignToolitps
  #tooltips.initializeTooltips()

  # handle table cell z-index for popups
  $(document).on 'mouseup', '.popup-toggle', (e) ->
    dropdown_open = $(this).closest('td').hasClass('last-clicked')
    $('td.last-clicked').removeClass('last-clicked')
    if !dropdown_open || mobile_detected
      $(this).closest('td').addClass('last-clicked')

  # handle table cell z-index for popups
  $(document).on 'mouseup', '.dropdown-popup .dropdown-menu', (e) ->
    $(this).closest('td').removeClass('last-clicked')

  # close popup on scroll
  $('.table-wrap').scroll ->
    popup = $('.datagrid .dropdown-popup')
    closePopup(popup)

  closePopup = (popup) ->
    if popup.length
      popup.removeClass('show')
      popup.find('.dropdown-menu').removeClass('show')
      popup.closest('td').removeClass("last-clicked")
  # stop clicks on popup toggler registering on body element
  $(document).on 'click', '.popup-toggle', (e) ->
    e.stopPropagation()
  # detect when body clicked (popup not clicked)
  $(document).on 'click', 'body', (e) ->
    popup = $('.datagrid .dropdown-popup')
    popup.closest('td').removeClass("last-clicked")

  # off canvas mobile sidebar
  $(document).on 'click', '.sidebar-trigger', (e) ->
    if $(this).hasClass('is-open')
      $('.sidebar-trigger').removeClass('is-open')
      $('.sm-convert-sidebar').removeClass('sidebar-open')
    else
      $('.sidebar-trigger').addClass('is-open')
      $('.sm-convert-sidebar').addClass('sidebar-open')

  # off canvas mobile sidebar
  if mobile_detected && $('.sm-convert-sidebar').length
    # sidebar adjustment for admin bars
    bar_adjustment = $('.admin-bar-wrapper').height() + 20
    if admin_bar_detected
      bar_adjustment = bar_adjustment + $('.student-info-bar-wrapper').height()

    $sidebar_content = $('.sm-convert-sidebar .page-body-sidebar .page-section-content')
    $sidebar_trigger = $('.sidebar-trigger')
    side_bar_offset = $('.sm-convert-sidebar').offset().top
    $(window).scroll (e) ->
      isPositionFixed = $sidebar_content.css('position') == 'fixed'
      if $(this).scrollTop() > (side_bar_offset - bar_adjustment) and !isPositionFixed
        $sidebar_content.css
          'position': 'fixed'
          'top': "#{bar_adjustment}px"
        $sidebar_trigger.css
          'position': 'fixed'
          'top': "#{bar_adjustment}px"
      if $(this).scrollTop() < (side_bar_offset - bar_adjustment) and isPositionFixed
        $sidebar_content.css
          'position': 'static'
          'top': '0px'
        $sidebar_trigger.css
          'position': 'absolute'
          'top': ""
      return
