class window.Formatting
  apply: ->
    # expand embedded iframes to be full page width and preserve their aspect ratio at all screen sizes
    $('.format-content .trix-content iframe, .format-content .video-wrapper iframe').each ->
      containerWidth = $(this).closest('.format-content').width()
      if $(window).width() < 768
        containerWidth = $(window).width() - 80
      elementRatio = $(this).width() / $(this).height()
      $(this).width(containerWidth)
      $(this).height(containerWidth / elementRatio)

$ ->
  formatting = new Formatting

  if $('.format-content').length
    setTimeout(formatting.apply, 100)

    $(window).on 'resize', (e) ->
      formatting.apply
