$ ->
  show_warning_field =(e) ->
    warning_field_trigger = $(e).data("logic-label")

    unless warning_field_trigger?
      return

    warning_div = $("body").find("[data-warning-field-trigger='" + warning_field_trigger + "']")
    warning_div.removeClass("hide").fadeIn()

    $('.disable-with-warning').each (i, obj) ->
      update_button_disability(obj, $(warning_div).find("input"))

  update_button_disability =(button_obj, warning_field) ->
    warning_val = $(warning_field).val()

    if warning_val != "I AGREE"
      $(button_obj).prop('disabled', true)
    else
      $(button_obj).prop('disabled', false)

  $("body").on 'change', '.warning-trigger', (e) ->
    show_warning_field(this)

  $("body").on 'keyup keypress blur click change', '.warning-field', (e) ->
    warning_field = this
    $('.disable-with-warning').each (i, obj) ->
      update_button_disability(obj, warning_field)
