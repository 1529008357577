calculateSum =(e) ->
  raw_sum_label = e.data("sum-label")

  label_array = raw_sum_label.split(',')

  $.each label_array, (i) ->
    sum_label = label_array[i]

    console.log sum_label
    console.log $(this)

    $('body').find(".sum[data-sum-label*='#{sum_label}']").each ->
      elem = $(this)

      sum = 0
      elem.val(sum.toFixed(2))

      $('body').find(".summable[data-sum-label*='#{sum_label}']").not('.summable-deleted').each ->
        if !isNaN(@value) and @value.length != 0
          sum += parseFloat(@value)
        else
          $(this).val( $(this).val().replace(/[^0-9.,]/g,'') )

      if $(this).hasClass('percentage-remaining')
        updateSumTotal(elem, (100 - sum) + "%")
      else if $(this).hasClass('percentage')
        updateSumTotal(elem, sum + "%")
      else
        updateSumTotal(elem, sum.toFixed(2))

updateSumTotal =(elem, value) ->
  if elem.hasClass('html-value')
    elem.html(value)
  else
    elem.val(value)
    elem.attr('value',elem.val())

$ ->
  #mark deleted rows (has a class of hide) on load (happens after validation fail on edit)
  if $('.sum').length
    $('body').find('.remove_fields.existing').each ->
      row = $(this).closest(".#{$(this).attr('data-wrapper-class')}")
      if row.hasClass('hide')
        row.find('.summable').addClass('summable-deleted')

  # sum on page load
  $('body').find(".sum").each ->
    calculateSum($(this))

  $('body').on 'keyup keypress blur click change', '.summable', (e) ->
    calculateSum($(this))

  # for payment plans - recalculate everything on delete
  $(document).on 'cocoon:after-remove cocoon:after-insert', (e)->
    if $('.sum').length
      $('body').find(".sum").each ->
        calculateSum($(this))

  # flag 'deleted' rows (actually just hidden) with a class so they can be excluded from summing
  $(document).on 'click','.remove_fields.existing', (e)->
    if $('.sum').length
      $(this).closest(".#{$(this).attr('data-wrapper-class')}").find('.summable').addClass('summable-deleted')
