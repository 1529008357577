$ ->
  $('.toggle-trigger').click (event) ->
    target_id = $(this).attr("data-target-id")
    if $("#" + target_id).hasClass("hide")
      $("#" + target_id).fadeIn()
      $("#" + target_id).removeClass("hide")
    else
      $("#" + target_id).fadeOut()
      $("#" + target_id).addClass("hide")
    event.preventDefault()

$ ->
  $(document).on 'mouseenter', '.hover-swap-button', (e) ->
    $(this).text($(this).attr("data-hover-text"))

  $(document).on 'mouseleave', '.hover-swap-button', (e) ->
    $(this).text($(this).attr("data-original-text"))
