on_resize = (c, t) ->

  onresize = ->
    clearTimeout t
    t = setTimeout(c, 100)
    return

  c

adjust_page_menu = ->
  navSpace = $('.page-menu-items').outerWidth()
  linksWidth = 0
  $('.page-menu-items > li').each ->
    linksWidth += $(this).outerWidth()
    return
  if linksWidth > navSpace
    while linksWidth > navSpace
      lastLink = $('.page-menu-items > li:last')
      lastLinkWidth = lastLink.outerWidth()
      $(lastLink).data 'foo', lastLinkWidth
      $('.page-menu-overflow').prepend lastLink
      linksWidth = linksWidth - lastLinkWidth
    $('.page-menu-more').show()
    $('.more-label').text $('.page-menu-overflow > li').length + " " + $('.more-label').attr('data-more-text')
  else
    while linksWidth <= navSpace
      firstOverflowLink = $('.page-menu-overflow > li:first')
      firstOverflowLinkWidth = firstOverflowLink.data('foo')
      if navSpace - linksWidth > firstOverflowLinkWidth
        $('.page-menu-items').append firstOverflowLink
      linksWidth = linksWidth + firstOverflowLinkWidth
    $('.more-label').text $('.page-menu-overflow > li').length + " " + $('.more-label').attr('data-more-text')
    if $('.page-menu-overflow > li').length == 0
      $('.page-menu-more').hide()
  return

$ ->
  if $('.page-menu').length
    $(window).resize ->
      adjust_page_menu()

    adjust_page_menu()
