elementsHTMLOverlap = ($div1, $div2) ->
  x1 = $div1.offset().left
  y1 = $div1.offset().top
  h1 = $div1.outerHeight(true)
  w1 = $div1.outerWidth(true)
  b1 = y1 + h1
  r1 = x1 + w1
  x2 = $div2.offset().left
  y2 = $div2.offset().top
  h2 = $div2.outerHeight(true)
  w2 = $div2.outerWidth(true)
  b2 = y2 + h2
  r2 = x2 + w2

  return false if (b1 <= y2 || y1 >= b2 || r1 <= x2 || x1 >= r2)
  return true

elementsOverlap = ($div1, $div2) ->
  return false if $div1.attr('data-day') != $div2.attr('data-day')
  # the deduction of 0.01 is to fix any rounding issues on the data values that could affect result
  (parseFloat($div1.attr('data-top')) + parseFloat($div1.attr('data-height')) - 0.01) > parseFloat($div2.attr('data-top'))

events_overlap_id = 1

class window.CalendarWeek

  setup: ->
    #scroll calendar to just before 8 AM
    container = $(".calendar-wrap")
    container.scrollTop((8 * 60) - 10)

    instance = this

    instance.containWithinView(container)

    $(window).on 'resize', (e) ->
      instance.containWithinView(container)

  containWithinView: (container) ->
    container.css("height", (($(window).height() - 20) - container.offset().top) + "px" )

  determineOverlaps: ->
    items = $(".calendar-event-position")
    items.each (index, element) ->
      nextElement = items.eq(index + 1)
      firstElementInGroup = $('overlap-group-'+ events_overlap_id).first
      # reset this element and start new group if this element does't overlap the first in the current group
      if firstElementInGroup.length
        if !elementsOverlap( $(this), firstElementInGroup )
          $(this).removeClass('overlap-group overlap-group-'+ events_overlap_id)
          events_overlap_id++
      # add this and next element to overlap group if they overlap
      if nextElement.length
        if elementsOverlap( $(this), nextElement )
          $(this).addClass('overlap-group overlap-group-'+ events_overlap_id).attr('data-overlap-group', events_overlap_id)
          $(nextElement).addClass('overlap-group overlap-group-'+ events_overlap_id).attr('data-overlap-group', events_overlap_id)
        else
          events_overlap_id++

  formatOverlaps: ->
    count = 1
    while count <= events_overlap_id
      thisGroup = $('.overlap-group-'+count)
      numberInGroup = thisGroup.length
      $('.overlap-group-'+count).css("width", (100 / numberInGroup) + "%")

      thisGroup.each (index) ->
        $(this).css("left", (index * 100/ numberInGroup) + "%" )

      count++

  setupCarousel: ->
    # MUST add carousel-inner class AFTER overlap calculations otherwise all offsets are 0
    $('#carousel-inner').addClass 'carousel-inner'
    # run carousel
    $('#carousel-week').carousel wrap: false, interval: 10000000
    # add swipe functionality
    #$('#carousel-week').bcSwipe threshold: 50
    # css class to enable dropdowns to sit on top like modals
    $('.calendar-event-position').on 'show.bs.dropdown', ->
      $('#carousel-week').addClass 'dropdown-opened'
      return
    $('.calendar-event-position').on 'hide.bs.dropdown', ->
      $('#carousel-week').removeClass 'dropdown-opened'
      return

$ ->

  if $('.simple-calendar.week-view').length
    calendarWeek = new CalendarWeek
    calendarWeek.setup()
    calendarWeek.determineOverlaps()
    calendarWeek.formatOverlaps()

  # mobile swipe view
  if $('.simple-calendar.week-view.swipeable').length
    calendarWeek.setupCarousel()
