$ ->

  $(".saved-views-menu-trigger").click ->
    $(".saved-view").toggle()
    $(".saved-view").removeClass("hide")

  $(".student-menu-trigger").click ->
    $('#student-slide-menu').animate {left: 0}, 200, ->
    $('body').addClass('menu-is-open').append('<div class="menu-overlay"></div>')
    $('.menu-overlay').delay(20).fadeIn(180).click ->
      $("#student-slide-menu .menu-close").trigger('click')

  $(".parent-menu-trigger").click ->
    $('#parent-slide-menu').animate {left: 0}, 200, ->
    $('body').addClass('menu-is-open').append('<div class="menu-overlay"></div>')
    $('.menu-overlay').delay(20).fadeIn(180).click ->
      $("#parent-slide-menu .menu-close").trigger('click')

  $("#student-slide-menu .menu-close").click ->
    $('#student-slide-menu').animate {left: -350}, 200, ->
      $('.menu-overlay').fadeOut 200, ->
        $('body').removeClass('menu-is-open')
        $(this).remove()

  $("#parent-slide-menu .menu-close").click ->
    $('#parent-slide-menu').animate {left: -350}, 200, ->
      $('.menu-overlay').fadeOut 200, ->
        $('body').removeClass('menu-is-open')
        $(this).remove()

  $(".slide-in-menu h3").click ->
    $(".menu-list.open").slideUp("fast").removeClass("open")
    if (!$(this).hasClass('list-open'))
      $(".slide-in-menu h3.list-open").removeClass("list-open")
      $(this).addClass('list-open').next(".menu-list").addClass('open').slideDown("fast")
    else
      $(this).removeClass("list-open").next(".menu-list").removeClass('open')

  $(".menu-trigger").click ->
    $('#slide-menu').animate {left: 0}, 200, ->
      $('body').addClass('menu-is-open').append('<div class="menu-overlay"></div>')
      $('.menu-overlay').delay(20).fadeIn(180).click ->
        $(".menu-close").trigger('click')
      if $(window).width() > 767
        $("#slide-menu #search").focus()

  $(".menu-close").click ->
    $('#slide-menu').animate {left: -350}, 200, ->
      $('.menu-overlay').fadeOut 200, ->
        $('body').removeClass('menu-is-open')
        $(this).remove()

  $("#slide-menu h3").click ->
    $(".menu-list.open").slideUp("fast").removeClass("open")
    if (!$(this).hasClass('list-open'))
      $("#slide-menu h3.list-open").removeClass("list-open")
      $(this).addClass('list-open').next(".menu-list").addClass('open').slideDown("fast")
    else
      $(this).removeClass("list-open").next(".menu-list").removeClass('open')

  # Filter Menu Controls
  $(".filter-menu-trigger").click ->
    $('#filter-slide-menu').animate {right: 0}, 200, ->
      $('#filter-slide-menu .datagrid-actions').css "display", "flex"
      $('body').addClass('menu-is-open').append('<div class="menu-overlay"></div>')
      $('.menu-overlay').delay(20).fadeIn(180).click ->
        $(".filter-menu-close").trigger('click')

  $(".filter-menu-close").click ->
    $('#filter-slide-menu .datagrid-actions').css "display", "none"
    $('#filter-slide-menu').animate {right: -300}, 200, ->
      $('.menu-overlay').fadeOut 200, ->
        $('body').removeClass('menu-is-open')
        $(this).remove()

  $("#filter-slide-menu h3").click ->
    $(".filter-section-content.open").slideUp("fast").removeClass("open")
    if (!$(this).hasClass('list-open'))
      $("#filter-slide-menu h3.list-open").removeClass("list-open")
      $(this).addClass('list-open').next(".filter-section-content").addClass('open').slideDown("fast")
    else
      $(this).removeClass("list-open").next(".filter-section-content").removeClass('open')
$ ->
  $(".clipboard-link").click (e) ->
    link = $(this)
    copy_text = $('#' + link.attr("data-target"))
    copy_text.removeClass("hide")
    copy_text.select()
    result = document.execCommand 'copy'
    copy_text.addClass("hide")
    if link.hasClass("link-boing")
      link.addClass("boing")
      setTimeout (->
        link.removeClass("boing")
        return
      ), 610

    if link.attr("data-message") != undefined
      e.preventDefault()
      e.stopPropagation()
      current_text = link.html()
      if link.find('.span-wrap').length > 0
        current_text = link.find('.span-wrap').html()
      link.html('<span class="' + link.attr("data-status-color") + ' fade-row">' + link.attr("data-message") + '</span>')
      setTimeout (->
        link.html('<span class="span-wrap fade-row">' + current_text + '</span>')
        return
      ), 1500
    else
      if result
        $('#' + $(this).attr("data-result")).removeClass("hide")
