# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

$ ->
  # autotomatic sequencing of questions when dynamically adding or removing a question
  $(document).on 'cocoon:after-remove cocoon:after-insert', (e)->
    if $('.auto-position-value').length
      updateAutoPositionValues()
    if $('.visible-auto-position-value').length
      updateVisibleAutoPositionValues()

  updateAutoPositionValues = ->
    item_count = $('.auto-position-value').length
    $('.auto-position-value').each (i, obj) ->
      input = $(this)
      # update input value in sequence
      input.val(i + 1)
      other_input = input.closest('.block').find('.copy-auto-position-value')
      # assign the default value to other input if last
      if other_input.hasClass('unless-last') && i == item_count - 1
        other_input.val(other_input.attr("data-default"))
      else
        other_input.val(i + 1)

  updateVisibleAutoPositionValues = ->
    position = 0
    item_count = 0
    $('.visible-auto-position-value').each (i, obj) ->
      if $(this).closest('.block').is(":visible")
        item_count += 1

    $('.visible-auto-position-value').each (i, obj) ->
      if $(this).closest('.block').is(":visible")
        position += 1
        input = $(this)
        # update input value in sequence
        input.val(position)
        other_input = input.closest('.block').find('.copy-auto-position-value')
        # assign the default value to other input if last
        if other_input.hasClass('unless-last') && position == item_count
          other_input.val(other_input.attr("data-default"))
        else
          other_input.val(position)

  $(".toggle-answer").click (e) ->
    $(this).hide()
    $("#" + $(this).data("target")).show()

  updateSurveyItems = (changed_elem) ->
    # console.log changed_elem
    if changed_elem.hasClass("radio_buttons") && !changed_elem.is(":checked")
      return

    changed_val = ""
    if changed_elem.val() && changed_elem.val() isnt ""
      changed_val = changed_elem.val().toString().toUpperCase()

    str = changed_elem[0].name.split("[").pop().split("]")[0]
    $("[data-parent='#{str}']").each ->
      # console.log $(this)
      target_elem = $(this)
      # console.log(target_elem)

      target_op = target_elem.data("op")
      # console.log(target_op)
      target_val = ""
      if target_elem.data("val") && target_elem.data("val") isnt ""
        target_val = target_elem.data("val").toString().toUpperCase()
      # console.log(target_val)

      if target_op == "equal"
        if changed_val is target_val
          # console.log(target_elem)
          target_elem.fadeIn()
        else
          target_elem.fadeOut()
      else if target_op == "not_equal"
        if changed_val isnt target_val
          target_elem.fadeIn()
        else
          target_elem.fadeOut()

  hb_hide = (element) ->

  hb_hide = (element) ->

  $(".survey-user-form").on "click", ".radio input", ->
    updateSurveyItems($(this))

  $(".survey-user-form").on "change", "select", ->
    updateSurveyItems($(this))

  $('.survey-answer-item').each ->
    #console.log $(this)
    radios = $(this).find('.radio input')
    #console.log radios
    if radios.length > 0
      #console.log "Radio Present"
      radios.each ->
        updateSurveyItems($(this))

    select = $(this).find('select')
    #console.log select
    if select.length > 0
      #console.log "Select Present"
      updateSurveyItems(select)

$ ->
  prevalidateSurveyRadios = (wrapper) ->
    if wrapper.length
      #disable button
      $("#{wrapper} .prevalidated-submit").addClass('disabled')
      #run on load
      validateRadios(wrapper)
      #after every change check each batch of radio buttons for one that's checked
      $(wrapper).on 'change','.input.radio_buttons input.radio_buttons', (e)->
        validateRadios(wrapper)

  validateRadios = (wrapper) ->
    complete = true
    $("#{wrapper} .input.radio_buttons").each ->
      if $(this).find("input.radio_buttons:checked").length == 0
        complete = false

    $("#{wrapper} .prevalidated-submit").removeClass('disabled') if complete

  prevalidateSurveyRadios(".prevalidated-radios")

  prevalidateTextInputs = (wrapper) ->
    if wrapper.length
      #disable button
      $("#{wrapper} .prevalidated-submit").addClass('disabled')
      #run on load
      validateTextInputs(wrapper)
      #after every change check each input
      $(document).on 'change keyup','.input.text textarea.text, .input.text input, .input.string input', (e)->
        validateTextInputs(wrapper, e)

  validateTextInputs = (wrapper, event = null ) ->
    if event == null
      # all instances
      group = $(wrapper)
    else
      # event on a specific group so use a scope of relevant wrapper
      group = $(event.target).closest(wrapper)

    # run for each wrapper
    group.each ->
      $(this).find(".prevalidated-submit").addClass('disabled')

      complete = false
      allFilled = true
      $(this).find(".input.text, .input.string").each ->
        allFilled = false unless $(this).find("textarea.text, input").val()
      complete = allFilled

      $(this).find(".prevalidated-submit").removeClass('disabled') if complete

  prevalidateTextInputs(".prevalidated-text-inputs")

  prevalidateFileUploads = (wrapper) ->
    if wrapper.length
      #disable button
      $("#{wrapper} .prevalidated-submit").addClass('disabled')
      #run on load
      validateFileUploads(wrapper)
      #after every change check for at least one file upload present
      $(wrapper).on 'change','.input.file input.file', (e)->
        validateFileUploads(wrapper)
      #after every add / delete check for at least one file upload present
      $(wrapper).on 'cocoon:after-remove cocoon:after-insert', (e)->
        validateFileUploads(wrapper)

  validateFileUploads = (wrapper) ->
    $("#{wrapper} .prevalidated-submit").addClass('disabled')
    complete = false
    allFilled = true if $("#{wrapper} .input.file, #{wrapper} .uploaded-file").length
    $("#{wrapper} .input.file").each ->
      allFilled = false unless $(this).find("input.file").val()
    complete = allFilled

    $("#{wrapper} .prevalidated-submit").removeClass('disabled') if complete

  prevalidateFileUploads(".prevalidated-uploads")
