$ ->
  # handling phone number inputs
  updatePhoneField = (elem) ->
    input_div = elem.closest(".phone-input")

    country_div = input_div.find("#phone_country")
    country = country_div.val()

    number_div = input_div.find(".phone-number")
    number = number_div.val()

    input_div.find(".target-input").val("#{country} #{number}")

    # don't try to dynamically format number
    # chunks = number.split(/' '|x/)
    # [..., last] = chunks
    #
    # if last.length > 0
    #   $.ajax
    #     data: $(this).serialize()
    #     url: "/phone_number?phone_number=#{encodeURIComponent("#{country} #{number}")}"
    #     type: "get"
    #     dataType: "JSON"
    #     headers:
    #       Accept: "text/javascript, */*; q=0.01"
    #     success: (data)->
    #       number_div.val(data["display"])
    #       input_div.find(".target-input").val(data["number"])
    #
    #     error: (xhr, status, error)->
    #       console.log(xhr)
    #       console.log(status)
    #       console.log(error)
    # else if number.length == 0
    #   input_div.find(".target-input").val("")

  delay = do ->
    timer = 0
    (callback, ms) ->
      clearTimeout timer
      timer = setTimeout(callback, ms)

  $(".phone-input").on "keyup", ".phone-number", ->
    changed = $(this)
    updatePhoneField(changed)
    # delay (->
    #   updatePhoneField(changed)
    # ), 300

  $(".phone-input").on "change", "#phone_country", ->
    updatePhoneField($(this))

  # handling address regions
  $("body").on "change", ".country-select", (e) ->
    country_select_obj = $(this)

    country_dom = country_select_obj.data("country-select-dom")
    country_code = country_select_obj.val()

    region_select_wrappers = $(".region-select-wrapper[data-country-select-dom=#{country_dom}]")
    region_text_wrappers = $(".region-text-wrapper[data-country-select-dom=#{country_dom}]")

    if country_code
      $.get "/addresses/regions/" + country_code, (regions) ->
        if regions.length > 0
          region_select_wrappers.each ->
            wrapper = $(this)
            region_select = wrapper.find(".region-select")
            old_selected = region_select.val()

            region_select.html "<option value=\"\">"+region_select.attr('data-default-text')+"</option>"
            $.each regions, ->
              region = this
              region_select.append "<option value=\"" + region[1] + "\">" + region[0] + "</option>"
            region_select.append "<option value=\"Other\">"+region_select.attr('data-other-text')+"</option>\n"

            region_select.attr "disabled", false
            region_select.val old_selected
            region_select.trigger "chosen:updated"

            wrapper.removeClass("hide")

          region_text_wrappers.each ->
            $(this).addClass("hide")

        else
          region_text_wrappers.each ->
            wrapper = $(this)
            region_text = wrapper.find(".region-text")

            region_text.attr "disabled", false
            region_text.trigger "chosen:updated"

            wrapper.removeClass("hide")

          region_select_wrappers.each ->
            $(this).addClass("hide")

    else
      $(".region-select[data-country-select-dom=#{country_dom}]").each ->
        $(this).attr "disabled", true
        $(this).trigger "chosen:updated"

      $(".region-text[data-country-select-dom=#{country_dom}]").each ->
        $(this).attr "disabled", true
        $(this).trigger "chosen:updated"

  $("body").on "submit", "form", (e) ->
    $(".region-select-wrapper.hide").each ->
      $(this).html("")

    $(".region-text-wrapper.hide").each ->
      $(this).html("")


$ ->
  $(".alert-success").delay(20000).fadeOut(3000)
  $(document).on 'click', '.close-alert', (e) ->
    $(this).closest('.flash-alert').hide()

# handle student profile bar pin save
$ ->
  $(".bar-trigger").click (e) ->
    if $(".student-info-bar").hasClass("bar-open")
      $('#user_setting_student_info_bar_open').val("false")
      $(".student-info-bar").removeClass("bar-open")
    else
      $('#user_setting_student_info_bar_open').val("true")
      $(".student-info-bar").addClass("bar-open")
    $.ajax
      type: 'PATCH',
      url: $('#student_info_nav_bar_form').attr('action'),
      dataType: 'JSON',
      data: $('#student_info_nav_bar_form').serialize(),
      beforeSend: (xhr) ->
        xhr.setRequestHeader 'X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')
        return

$ ->
  keys = {}
  $(document).keydown (e) ->
    keys[e.which] = true
    if keys[18] and keys[70]
      $('#search-toggle').trigger 'click'
    return
  $(document).keyup (e) ->
    delete keys[e.which]
    return
