$ ->
  # pass trigger clicks through to actual link
  $(document).on 'click', '.remote-row-trigger', ->
    $(this).closest("tr.has-children").find(".remote-rows-link")[0].click()

  #set status class on containing parent tr when showing children
  $(document).on 'click', '.remote-rows-link', ->
    $(this).closest("tr.has-children").toggleClass("showing-children")

  # add loading class - whole remote-rows-link element replaced in remote_rows.js.erb once run to prevent additional requests
  $(document).on 'ajax:send', '.remote-rows-link', ->
    $(this).addClass("remote-loading").removeAttr("href")
    $(this).closest("tr").addClass("showing-children")

  #toggle child rows when link is in toggle mode
  $(document).on 'click', '.remote-rows-link.remote-rows-toggle', ->
    parent = $(this).closest("tr").attr('id')
    if $(this).hasClass('open')
      $(".#{parent}").hide().removeClass('success-fade');
    else
      $(".#{parent}").show()
    $(this).toggleClass('open')

$ ->
  #get whole grid based on link href
  $(document).on 'click', '.get-grid',(e) ->
    e.preventDefault()
    container = $(this).closest(".table-wrap")
    # overlay ther loading animation whne loading grid
    container.addClass("is-loading")
    #fetch content based on link url
    $.get $(this).attr('href'), 'get_grid=1', (data) ->
      container.html '<div class="fade-in">' + data.grid + '</div>'
      container.removeClass('is-loading')

  # make context menu display on top of other rows
  $(document).on 'mousedown', '.context-menu .dropdown-toggle', (e) ->
    $('td.context_menu_column').css('z-index', '2')
    $(e.target).closest('td.context_menu_column').css('z-index', '3')

# ---  LOAD PAGES ON SCROLL ---
$ ->
  scroll_container = $('.load-on-scroll')
  mobile_detected = $('body').hasClass('mobile_detected')

  if scroll_container.length
    set_table_container_height(scroll_container, mobile_detected)
    setup_loading_text(scroll_container)

    $(window).resize ->
      set_table_container_height(scroll_container, mobile_detected)

  scroll_container.scroll ->
    # Bottom of the list reached?
    if (scroll_container.find('tbody').height() - $(this).height() - 50 < $(this).scrollTop()) and page_chunk_loading == false and scroll_container.hasClass("more-pages")
      get_next_page_chunk(scroll_container)

page_chunk_loading = false

set_table_container_height = (container, mobile_detected = false) ->
  if mobile_detected
    bottom_space = 10
  else
    bottom_space = 30
  container.height ($(window).height() - ($('.load-on-scroll').offset().top)) - bottom_space

setup_loading_text = (container) ->
  container.append(jQuery.parseHTML("<div class='loading-message fade-row'>#{container.attr("data-loading-text")}</div>"))

get_next_page_chunk = (container) ->
  page_chunk_loading = true
  container.addClass 'records-loading'
  $.ajax
    type: 'GET'
    url: "#{container.attr("data-next-page-url")}"
    contentType: 'application/json; charset=utf-8'
    data: "get_page=true"
    success: (data) ->
      #create jquery object from html
      table = $($.parseHTML(data.grid))
      # strip jquery object down to table rows only and insert after existing rows
      container.find("tbody").append(table.find("tbody").html())
      # update the number of the next page not yet displayed
      container.attr("data-next-page-url", data.next_page_url)
      # prevent more ajax requests if there are no more pages
      container.removeClass("more-pages") if data.more_pages == false
      # update number of records in view in the select all bar
      $("#number_of_records_displayed").html($('td.mass-actions-selector').length)
      # check mass aaction selectors if currently selecting all in view
      if container.find("table.selected-all-true").length
        $("input.mass-actions-selector").prop('checked', true)
      # render checkboxes
      fields = new Fields
      fields.renderFields()
      # tidy up
      container.removeClass 'records-loading'
      page_chunk_loading = false

    error: (req, status, error) ->
      console.log 'error fetching data'
