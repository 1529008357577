// Underline
Trix.config.textAttributes.underline = {
    style: { "textDecoration": "underline" },
    inheritable: true,
    parser: function (element) {
      var style = window.getComputedStyle(element);
      return style.textDecoration === "underline";
    }
}

// Section Element
Trix.config.blockAttributes.section = { tagName: "section", nestable: true }

addEventListener("trix-initialize", event => {
  const { toolbarElement } = event.target
  const blockTools = toolbarElement.querySelector("[data-trix-button-group=block-tools]")
  blockTools.insertAdjacentHTML("afterbegin", `
    <button type="button" class="trix-button" data-trix-action="x-horizontal-rule" title="Horizontal Rule" tabindex="-1">━</button>
  `)

  trix = event.target;
  toolBar = trix.toolbarElement;

  // // Creation of the button
  button = document.createElement("button");
  button.setAttribute("type", "button");
  button.setAttribute("class", "trix-button trix-button--icon trix-button--icon-underline");
  button.setAttribute("data-trix-attribute", "underline");
  button.setAttribute("title", "underline");
  button.setAttribute("tabindex", "-1");
  button.innerText = "U";

  // Attachment of the button to the toolBar
  uploadButton = toolBar.querySelector('.trix-button-group--text-tools').appendChild(button);

  // Add Section Element button
  const buttonHTML  = '<button type="button" class="trix-button" data-trix-attribute="section" title="Custom Section">[ ]</button>'
  const groupElement = event.target.toolbarElement.querySelector(".trix-button-group--block-tools")
  groupElement.insertAdjacentHTML("beforeend", buttonHTML)

})

addEventListener("trix-action-invoke", event => {
  if (event.actionName == "x-horizontal-rule") {
    const { editor } = event.target
    const attachment = new Trix.Attachment({ content: "<hr>", contentType: "application/vnd.rubyonrails.horizontal-rule.html" })
    editor.insertAttachment(attachment)
  }
})

addEventListener("trix-toolbar-dialog-show", (event) =>{
  inputSelector = ".trix-dialog textarea[disabled]"
  //for inputElement in event.target.toolbarElement.querySelectorAll(inputSelector)
  $(inputSelector).removeAttr("disabled")
})

addEventListener("trix-initialize", event => {
  const { toolbarElement } = event.target
  const blockTools = toolbarElement.querySelector("[data-trix-button-group=block-tools]")
  const dialogGroup = toolbarElement.querySelector(".trix-dialogs")
  blockTools.insertAdjacentHTML("afterbegin", `
    <button type="button" class="trix-button trix-embed-custom-content" data-trix-action="x-custom-embed" data-trix-attribute="custom-embed" title="Embed" tabindex="-2">HTML</button>
  `)
  dialogGroup.insertAdjacentHTML("afterbegin", `
    <div class="trix-dialog trix-dialog--custom-embed" data-trix-dialog="custom-embed" data-trix-dialog-attribute="custom-embed">
      <div class="trix-dialog__custom-embed-fields">
        <textarea type="text" name="x-custom-embed" class="trix-input trix-input--dialog custom-content-holder" placeholder="Enter your html..." aria-label="embed code" required="" data-trix-input="" disabled="disabled"></textarea>
        <div class="trix-button-group">
          <input type="button" class="trix-button trix-button--dialog embed-custom-content" data-trix-custom="add-custom-embed" value="Add">
        </div>
      </div>
    </div>
  `)
})

$(document).on('click', '.embed-custom-content',function(event){
  var thisTrix = event.target.closest('.rich_text_area').querySelector("trix-editor");
  var htmlHolder = event.target.closest('.rich_text_area').querySelector(".custom-content-holder");

  if (htmlHolder.value != '')
  {
    $.ajax({
      method: "POST",
      url: "/embeds",
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: { embed: { content: htmlHolder.value } },
      success: ({ content, sgid }) => {
        // console.log(sgid)
        // console.log(content)
        attachment = new Trix.Attachment(
          {
            sgid: sgid,
            content: htmlHolder.value
          }
        );
        htmlHolder.value = '';
        thisTrix.editor.insertAttachment(attachment);
        thisTrix.editor.selectionManager.unlock();
      },
    });
  }

});
