validate_range = ($element) ->
  value = parseFloat($element.val())
  if !isNaN(value) and parseFloat($element.attr('data-min')) <= value and value <= parseFloat($element.attr('data-max'))
    true
  else
    false

display_error = ($element, error_text) ->
  remove_error $element
  $('<span class=\'validation-error error\'>' + error_text + '</span>').insertAfter $element

remove_error = ($element) ->
  $error_element = $element.next('.validation-error')
  if $error_element.length
    $error_element.remove()

$ ->
  # elements with .validate-range class will be validated to be between data-min, data-max attributes
  $('.validate-range').on 'input', ->
    $element = $(this)
    if !validate_range($element)
      display_error $element, 'Enter a value between ' + $element.attr('data-min') + ' and ' + $element.attr('data-max')
    else
      remove_error $element
