jQuery.fn.isFullyOnScreen = function(topMargin, bottomMargin) {
  var win = jQuery(window);
  var viewport = {
    top: win.scrollTop(),
    left: win.scrollLeft()
  };
  viewport.bottom = viewport.top + win.height();

  var bounds = this.offset();
  bounds.bottom = bounds.top + this.outerHeight();

  return !(viewport.bottom - parseInt(bottomMargin)  < bounds.bottom || viewport.top + parseInt(topMargin) > bounds.top);
};
